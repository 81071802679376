import {Suspense, useEffect} from 'react';
import {HelmetProvider} from 'react-helmet-async';
import ReactGA from 'react-ga4';
import SEO from './components/ui/layout/seo';
import {disableReactDevTools} from './lib/disableReactDevTools';
import Router from './router/router';
import React from 'react';

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

const App = () => {
  // INIT ANALYTICS
  useEffect(() => {
    ReactGA.initialize('G-QGNLF009K9');
  }, []);

  return (
    <React.StrictMode>
      <HelmetProvider>
        <SEO />
        <Suspense fallback={null}>
          <Router />
        </Suspense>
      </HelmetProvider>
    </React.StrictMode>
  );
};

export default App;
