import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';

const SEO = () => {
  const {i18n} = useTranslation();
  const selectedLanguage = i18n.language;
  const isPt = selectedLanguage.includes('pt');

  const desc = isPt
    ? 'Metalogic. Da concepção à instalação, confie em nossa expertise para estruturas metálicas duráveis e de alta qualidade. Braga.'
    : 'Metalogic. From conception to installation, trust our expertise for durable and high-quality metal structures. Braga.';

  const title = isPt ? 'Estruturas Metálicas' : 'Metal Structures';

  const keywords = isPt
    ? 'metalogic, construção metálica, construções metálicas, estruturas de aço, serralheria arquitetônica, soluções de construção em metal, obras personalizadas em metal, engenharia de metal, pavilhões agrícolas, hangares de aviação, estruturas metálicas personalizadas, fabricação de estruturas metálicas, soluções industriais em metal, construção de pavilhões metálicos, construção de hangares metálicos, projetos de engenharia metálica, estruturas para indústrias, construção de estruturas metálicas duráveis, braga'
    : 'metalogic, metal construction, metal constructions, steel structures, architectural metalwork, metal construction solutions, custom metalwork, metal engineering, agricultural pavilions, aviation hangars, custom metal structures, metal structures fabrication, industrial metal solutions, metal pavilions construction, aviation hangars construction, metal engineering projects, industrial structures, durable metal structures construction, braga';

  const canonical = isPt ? 'https://metalogic.pt' : 'https://metalogic.pt/en/';

  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={desc} />
      <meta name="keywords" content={keywords} />
      <meta name="title" content={title} />
      <link rel="canonical" hrefLang={isPt ? 'pt' : 'en'} href={canonical} />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={desc} />
      <meta property="og:url" content="https://metalogic.pt/" />
      <meta property="og:site_name" content="Metalogic - Estruturas Metálicas" />
      <meta property="og:locale" content={isPt ? 'pt' : 'en'} />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:creator" content="Metalogic by AR Group" />
      {/* <meta name="twitter:card" content="summary_large_image" /> */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={desc} />
      <meta name="twitter:site" content="@metalogic" />
      {/* End Twitter tags */}
      {/* ld+json script  */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "HomeAndConstructionBusiness",
            "name": "Metalogic",
            "description": "${desc}",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Rua do Castelo 41",
              "addressLocality": "Braga",
              "postalCode": "4700-311",
              "addressRegion": "Braga",
              "addressCountry": "PT"
            },
            "url": "https://metalogic.pt",
            "telephone": "+351253786001",
            "image": ["https://metalogic.pt", "https://metalogic.pt/favicon.ico"]
          }
      `}
      </script>
    </Helmet>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export default SEO;
