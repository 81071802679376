import i18n, {InitOptions} from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

import translations from './locales';

// CONFIG i18n
const i18nConfig: InitOptions = {
  resources: translations,
  lng: 'pt',
  fallbackLng: 'pt',
  defaultNS: 'translations',
  interpolation: {
    escapeValue: false
  }
};

i18n.use(LanguageDetector).use(initReactI18next).init(i18nConfig);

export default i18n;
