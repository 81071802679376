export default {
  translations: {
    hero: {
      title: '<animated>Strength</animated> of metal construction',
      animatedWords: [
        'Strength',
        'Reliability',
        'Value',
        'Force',
        'Sustainability',
        'Profitability',
        'Advantage',
        'Quality',
        'Durability',
        'Resistance',
        'versatility'
      ],
      description: 'Metal structure solutions to suit your project.',
      button: 'our services',
      footer: 'a company'
    },
    intro: {
      title: 'The <green>metalogic</green> for your solution!',
      description1:
        'With <white>Metalogic</white> you not only have the solidity of a metal construction, but also the quality and trust that we place in every project.',
      description2:
        'Together with <white>AR Group</white>, the idea of a collaboration between partners arose, which has developed and matured over more than 10 years, through mutual cooperation in national and international construction projects. <white>This is how Metalogic was born!</white>',
      stats1: 'Completed projects',
      stats2: 'Prizes won',
      stats3: 'Clients and partners',
      stats4: 'Prizes won',
      descriptionCard: 'metalogic co-founders'
    },
    advantages: {
      title: 'the advantages of <green>metallogic structures</green>',
      description: 'We deliver your project turnkey, bringing together and supplying all the specialties of your work. ',
      footer:
        'As an integral part of AR Group, Metalogic not only adds to but also benefits from its range of resources and puts them at the service of its customers.',
      button: 'learn more',
      option1: 'Resistance and Durability',
      option2: 'Fast Construction',
      option3: 'Flexibilidade no Design',
      option4: 'Design Flexibility',
      option5: 'Sustainability and Recyclability',
      option6: 'Low Maintenance',
      option7: 'Precision and Quality',
      option8: 'Fire Resistance',
      option9: 'Robustness',
      option10: 'Sustainability',
      option11: 'Recyclability'
    },
    solutions: {
      title: 'our <green>solutions</green>',
      data: [
        {
          title: 'metal',
          description: 'structures',
          descriptionBack: 'We design, manufacture and assemble robust, certified metal structures to the highest quality standards.'
        },
        {
          title: 'agricultural, equestrian',
          description: 'and livestock pavilions',
          descriptionBack: 'Tailor-made solutions for your farm, animal farm, equestrian center, warehouses, riding arenas, cowsheds, pig farms.'
        },
        {
          title: 'metal',
          description: 'roofing',
          descriptionBack: 'Services ranging from asbestos removal, roof replacement, structural rehabilitation and parking lot covers.'
        },
        {
          title: 'sports and recreation',
          description: 'halls',
          descriptionBack:
            'Paddle tennis courts, multi-purpose pavilions, indoor tennis courts, sports pavilions in general, with all the regulatory requirements'
        },
        {
          title: 'industrial',
          description: 'buildings',
          descriptionBack: 'Metal, concrete or mixed concrete and steel structure with thermal insulation, ventilation and fire protection.'
        },
        {
          title: 'aviation',
          description: 'hangars',
          descriptionBack: 'Metal hangars for aircraft, planes or helicopters, with the right flooring and custom-made megadoor.'
        },
        {
          title: 'warehouses and logistics',
          description: 'centers',
          descriptionBack: 'Based on the size, layout and features required, we will make an optimized and efficient space.'
        },
        {
          title: 'metalworks',
          descriptionBack: 'Design, manufacture and assembly of light structures/metalworks such as stairs, balconies, handrails, guardrails.'
        },
        {
          title: 'special',
          description: 'structures',
          descriptionBack: 'Structures for mining, for the petrochemical industry, roofs, special installations of any degree.'
        }
      ]
    },
    desginBuild: {
      title: '<green>design-build</green> with metalogic',
      description1:
        'At <grey>Metalogic</grey>, we design and model your Metal Structure in a sustainable way, according to your needs and expectations!',
      description2:
        'Combining all areas of activity inherent to the construction sector, from Design to Construction, and from Management to Manufacturing and Assembly.',
      button: 'get a quote'
    },
    mission: {
      title: 'our mission',
      description1:
        'At <white>Metalogic</white>, the commitment to innovation is a priority and fundamental, based on the strong dedication, experience and continuous training of our professionals.',
      description2:
        'Our approach is very firmly based on establishing long-term partnership relationships with our customers and suppliers, founded on trust between the parties, providing everyone with a solid and sustained investment in joint projects.',
      more: 'Learn More'
    },
    contact: {
      title: "We'll shape your project",
      description1:
        'If you have a project or idea that you need to develop, with <white>Metalogic</white> you will find all the support and resources to bring your project to life!',
      description2: 'Request your quote now and bring your project to life, we will support you throughout the process.',
      description3: 'Shall we lay the foundations for your project?',
      description4: "The customer's success will be ours!",
      form: {
        name: 'Name',
        email: 'Email',
        phone: 'Telephone',
        activity: 'Sector of activity',
        country: 'Country',
        aproved: 'Do you have an approved project?',
        description: 'Project description',
        footer: 'By submitting you are allowing Metalogic to process the personal information submitted above in order to contact you.',
        button: 'request a quote',
        error: {
          required1: 'Required',
          required2: 'This field must be filled in.',
          email: 'This is not a valid e-mail address.',
          description1: 'The description must be at least 5 characters long.',
          description2: 'The description should be no longer than 500 characters.'
        },
        sucessMessage: "We'll contact you soon",
        errorMessage: 'Try again'
      }
    },
    index: {
      quemSomos: 'who we are',
      vantagens: 'advantages',
      servicos: 'services',
      designBuild: 'design-build',
      missao: 'mission',
      contact: 'contact'
    },
    footer: {
      email: 'email',
      address: 'address',
      contact: 'contact',
      social: 'social',
      policy: 'Privacy Policy',
      terms: 'Terms and conditions',
      politic: 'Cookie Policy',
      rights: 'All rights reserved.'
    }
  }
};
