export default {
  translations: {
    hero: {
      title: 'a <animated>solidez</animated> da construção metálica',
      animatedWords: [
        'Solidez',
        'Fiabilidade',
        'Mais-Valia',
        'Força',
        'Sustentabilidade',
        'Rentabilidade',
        'Vantagem',
        'Qualidade',
        'Durabilidade',
        'Resistência',
        'Versatibilidade'
      ],
      description: 'As soluções de estruturas metálicas que se adaptam ao seu projecto.',
      button: 'ver serviços',
      footer: 'uma empresa'
    },
    intro: {
      title: 'A <green>metalogic</green> para a sua solução!',
      description1:
        'Com a <white>Metalogic</white> não tem apenas a solidez de uma construção metálica, mas também a qualidade e confiança que depositamos em todos os projetos.',
      description2:
        'Em conjunto com o <white>AR Group</white>, surgiu a ideia de uma colaboração entre parceiros, que se desenvolveu e amadureceu ao longo de mais de 10 anos, através da cooperação mútua em projectos de construção, nacionais e internacionais. <white>Assim nasceu a Metalogic!</white>',
      stats1: 'Projectos concluidos',
      stats2: 'Prêmios ganhos',
      stats3: 'Clientes e parceiros',
      stats4: 'Prêmios ganhos',
      descriptionCard: 'metalogic co-fundadores'
    },
    advantages: {
      title: 'as vantagens das <green>estruturas metalogic</green>',
      description: 'Entregamos o seu Projeto Chave-Na-Mão, juntando e fornecendo todas as especialidades da sua obra.',
      footer:
        'Como parte integrante da AR Group, a Metalogic vem não só acrescentar, mas também beneficiar do seu leque de recursos e colocá-los ao serviço dos seus clientes.',
      button: 'saber mais',
      option1: 'Resistência e Durabilidade',
      option2: 'Rapidez na Construção',
      option3: 'Flexibilidade no Design',
      option4: 'Adaptabilidade e Expansão',
      option5: 'Sustentabilidade e Reciclabilidade',
      option6: 'Manutenção Reduzida',
      option7: 'Precisão e Qualidade',
      option8: 'Resistência ao Fogo',
      option9: 'Robutez',
      option10: 'Sustentabilidade',
      option11: 'Reciclabilidade'
    },
    solutions: {
      title: 'as nossas <green>soluções</green>',
      data: [
        {
          title: 'estruturas',
          description: 'metálicas',
          descriptionBack:
            'Dimensionamos, fabricamos e montamos estruturas metálicas robustas, certificadas e de acordo com os mais elevados padrões de qualidade.'
        },
        {
          title: 'pavilhões agrícolas,',
          description: 'equestres e agropecuários',
          descriptionBack: 'Soluções à medida da sua exploração agrícola, animal, centro hípico, armazéns, picadeiros, vacarias, suinicultura.'
        },
        {
          title: 'coberturas',
          description: 'metálicas',
          descriptionBack:
            'Serviços desde a Remoção de Amianto, substituição de coberturas, a reabilitação estrutural e coberturas de estacionamento.'
        },
        {
          title: 'pavilhões desportivos',
          description: 'e recreativos',
          descriptionBack:
            'Campos de padel, pavilhões multiusos, campos de ténis cobertos, pavilhões desportivos em geral, com todos as exigências regulamentares.'
        },
        {
          title: 'pavilhões',
          description: 'industriais',
          descriptionBack: 'Estrutura metálica, de betão ou estrutura mista de betão e aço, com isolamento térmico, ventilação e protecção ao fogo.'
        },
        {
          title: 'hangares de',
          description: 'aviação',
          descriptionBack: 'Hangares metálicos para aeronaves, aviões ou helicópteros, com o piso adequado e a megadoor à medida.'
        },
        {
          title: 'armazéns e centros',
          description: 'de logística',
          descriptionBack: 'Com base no tamanho, layout e características necessárias, faremos um espaço otimizado e eficiente.'
        },
        {
          title: 'serralharia',
          descriptionBack: 'Conceção, fabrico e montagem de estruturas ligeiras/serralharias tais como escadas, varandins, corrimãos, guarda-corpos.'
        },
        {
          title: 'estruturas',
          description: 'especiais',
          descriptionBack: 'Estruturas para mineração, para indústria petroquímica, coberturas, instalações especiais de qualquer grau de exigência.'
        }
      ]
    },
    desginBuild: {
      title: '<green>design-build</green> com metalogic',
      description1:
        'Na <grey>Metalogic</grey>, concebemos e modelamos a sua Estrutura Metálica de modo sustentável, em função das suas necessidades e expectativas!',
      description2:
        'Conjugando todas as áreas de atividade inerentes ao sector construtivo, desde a Concepção à Construção, e da Gestão ao Fabrico e Montagem.',
      button: 'receba um orçamento'
    },
    mission: {
      title: 'a nossa missão',
      description1:
        'Na <white>Metalogic</white>, a aposta na inovação é prioritária e fundamental, baseada numa forte dedicação, experiência e formação contínua dos nossos profissionais.',
      description2:
        'A nossa abordagem assenta, de forma muito decidida, no estabelecimento de relações de parceria a longo prazo com os nossos clientes e fornecedores, fundadas na confiança entre as partes, proporcionando a todos um investimento sólido e sustentado nos projetos conjuntos.',
      more: 'Saber mais'
    },
    contact: {
      title: 'Vamos moldar o seu projeto',
      description1:
        'Se tem um projeto ou uma ideia que precisa de desenvolver, com a <white>Metalogic</white> vai encontrar todo o apoio e recursos necessários para dar vida ao seu projeto!',
      description2: 'Peça já o seu orçamento e dê vida ao seu projeto, iremos acompanhá-lo em todo o processo.',
      description3: 'Vamos criar os alicerces para o seu projeto?',
      description4: 'O sucesso do cliente será o nosso!',
      form: {
        name: 'Nome',
        email: 'Email',
        phone: 'Telefone',
        activity: 'Setor de atividade',
        country: 'Pais',
        aproved: 'Tem projeto aprovado?',
        description: 'Descrição do projeto',
        footer: 'Ao submeter está a permitir que a Metalogic processe as informações pessoais enviadas acima para entrar em contacto.',
        button: 'pedir orçamento',
        error: {
          required1: 'Obrigatório',
          required2: 'Este campo tem de ser preenchido.',
          email: 'Este não é um e-mail válido.',
          description1: 'A descrição deve ter pelo menos 5 caracteres.',
          description2: 'A descrição não deve ter mais de 500 caracteres.'
        },
        sucessMessage: 'Iremos contacta-lo em breve',
        errorMessage: 'Tente de novo'
      }
    },
    index: {
      quemSomos: 'quem somos',
      vantagens: 'vantagens',
      servicos: 'serviços',
      designBuild: 'design-build',
      missao: 'missão',
      contact: 'contacto'
    },
    footer: {
      email: 'email',
      address: 'morada',
      contact: 'contacto',
      social: 'siga-nos',
      policy: 'Política de Privacidade',
      terms: 'Termos e Condições',
      politic: 'Política de Cookies',
      rights: 'Todos os direitos reservados.'
    }
  }
};
